export const environment = {
  production: false,
  disableGlobalErrorHandler: true,
  BaseUrl: 'https://new-dev.xpay.app/api/',
  ENV_NAME: 'develop',
  SENTRY_DSN: 'https://d9c00cd238684b689a0948e75060d994@sentry.io/1888118',
  BANQUE_MISR: {
    interaction: {
      operation: 'PURCHASE',
      merchant: {
        logo:
          'https://storage.googleapis.com/communities-media/images/xpay logo white.svg',
        name: 'XPay',
        address: {
          line1: 'Greek Campus, 28 Falaki St., Bab El Louk, 11513, Cairo'
        }
      }
    }
  },
  PaymentCoreUrl: 'https://dev-payment.xpay.app/',
  MixPanel_ProjectToken: '33dd9eefa8c2806436ab49e13397693c'
};
